<template>
  <el-cascader
    :options="province"
    @active-item-change="choseCity"
    @change="handleItemChange"
    v-model="selectedOptions"
    placeholder="请选择市区"
    filterable
    :disabled="disabled"
  ></el-cascader>
</template>
<script>
import find from "lodash/find";
import { sysArea } from "@/api/activity";
import qs from "qs";
export default {
  props: ["myData",'disabled'],

  data() {
    return {
      selectedOptions: [], //默认选中
      province: [], //省
      city: [], //市
      block: [], //区县
      props: {
        value: "label",
        children: "cities",
      },
    };
  },

  computed: {
    info() {
      return {};
    },
  },

  watch: {
    selectedOptions(val) {
      if (val.length) {
        this.province.forEach((ele) => {
          if (ele.value == this.selectedOptions[0]) {
            this.info.provinceName = ele.label;
            this.info.province = ele.value;
          }
        });
        this.city.forEach((ele) => {
          if (ele.value == this.selectedOptions[1]) {
            this.info.cityName = ele.label;
            this.info.city = ele.value;
          }
        });
        this.block.forEach((ele) => {
          if (ele.value == this.selectedOptions[2]) {
            this.info.blockName = ele.label;
            this.info.block = ele.value;
          }
        });
        this.$nextTick(() => {
          this.$emit("input", this.info);
        });
        // console.log(this.info);
      }
    },
    myData: {
      handler(val, o) {
        let arr = [];
        if (this.myData.block) {
          arr.push(this.myData.province, this.myData.city, this.myData.block);
        } else {
          arr = [this.myData.province];
        }
        this.selectedOptions = arr;
        console.log(arr);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },

  mounted() {
    this.getCityList();
  },

  methods: {
    getCityList() {
      let that = this;
      let params = {
        intParam4: 1,
        intParam2: 1,
        intParam1: 10000,
      };
	  this.$httpAes({
	    url: this.$http.adornUrl("/sysArea/list"), //'/static/json/map.json',////
	    method: "post",
	    data: params,
	  }).then(function(res) {
      // sysArea(params).then((res) => {
        if (res) {
			console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!/sysArea/list")
				   console.log(res.data.data)				
		
			
          var data = res.data.data;
		
          data.push(
            {
              code: "810100",
              name: "香港特别行政区",
            },
            {
              code: "820100",
              name: "澳门特别行政区",
            }
          );
          // 省市区数据分类
          that.province = [];
          that.city = [];
          that.block = [];
          // 省市区数据分类
          for (let i = 0; i < data.length; i++) {
            if (data[i].code.match(/0000$/)) {
              //省
              that.province.push({
                value: data[i].code,
                label: data[i].name,
                children: [],
              });
            } else if (data[i].code.match(/00$/)) {
              //市
              that.city.push({
                value: data[i].code,
                label: data[i].name,
                children: [],
              });
            } else {
              //区
              that.block.push({ value: data[i].code, label: data[i].name });
            }
          }
          // 分类市级
          for (var index in that.province) {
            for (var index1 in that.city) {
              if (
                that.province[index].value.slice(0, 2) ===
                that.city[index1].value.slice(0, 2)
              ) {
                that.province[index].children.push(that.city[index1]);
              }
            }
          }
          // 分类区级
          for (var item1 in that.city) {
            for (var item2 in that.block) {
              if (
                that.block[item2].value.slice(0, 4) ===
                that.city[item1].value.slice(0, 4)
              ) {
                that.city[item1].children.push(that.block[item2]);
              }
            }
          }
          // this.province = this.getTreeData(this.province);
          // console.log(this.province);
        } else {
          console.log(response.status);
        }
      });
	
    },
    getTreeData(data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          if (data[i] && data[i].children) {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
      }
      return data;
    },

    // 选市
    choseCity(e) {
      let that = this;
      for (var index3 in this.city) {
        if (e === this.city[index3].value) {
          that.block = this.city[index3].children;
        }
      }
    },

    handleItemChange(val) {
      console.log(val);
      if (val.length == 1) {
        this.selectedOptions.push(val[0]);
      }
      this.$nextTick(() => {
        this.$emit("input", this.selectedOptions);
      });
    },
  },
};
</script>

